<template>
  <div>
    <EditDialog width="50%" :isReturn="true" @closeFn="$emit('offsetCancel')" :isShow.sync="isShow">
      <div slot="title" class="title">提交意见</div>
      <div slot="content" class="content">
        <div class="content-title">
          <span>提交过后即可在详情中进行单据打印，并将相关纸质材料送交财务部。</span>
        </div>
        <el-input
          v-model.trim="opinion"
          type="textarea"
          :autosize="{ minRows: 8, maxRows: 999 }"
        ></el-input>

        <div v-if="isPulic" class="isPlic">
          <div>
            <p style="padding: 5px 0 5px 20%">
              您当前的报销单包含<span style="color: red">交通费</span>或<span style="color: red"
                >住宿费</span
              >
            </p>
            <p style="padding: 5px 0">
              请确认属于<span style="color: red">办公费、业务招待费、差旅费、招投标费</span
              >中的哪一项
            </p>
          </div>
        </div>
      </div>
      <div slot="footer" class="footer">
        <el-button type="primary" v-loading="loading" @click="submitFn">提交</el-button>
        <el-button type="info" plain @click="$emit('offsetCancel')">返回</el-button>
      </div>
    </EditDialog>
  </div>
</template>

<script>
export default {
  name: 'SubmitOpinionDialog',
  components: {
    EditDialog: () => import('@/components/EditDialog.vue'),
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    isPulic: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false, // 表格 加载
      opinion: null,
    }
  },
  computed: {},
  created() {},
  watch: {},
  methods: {
    submitFn() {
      if (!this.loading) {
        this.loading = true
        this.$emit('submitOpinionFn', this.opinion)
        this.loading = false
      }
    },
  },
}
</script>

<style scoped lang="scss">
.isPlic {
  display: flex;
  align-items: center;
  justify-content: center;
}
.content-title {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
  color: red;
  font-weight: 700;
  font-size: 18px;
}
</style>
